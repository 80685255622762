
import { Component, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import { apiRoleList, apiRoleDelete } from '@/api/setting/permissions'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { PageMode } from '@/utils/type'

@Component({
  components: {
    LsDialog,
    LsPagination,
  },
})
export default class Role extends Vue {
  /** S Data **/
  pager: RequestPaging = new RequestPaging()

  /** E Data **/

  /** S Methods **/
  // 添加角色
  onRoleAdd(): void {
    this.$router.push({
      path: '/setting/permissions/role_edit',
      query: { mode: PageMode['ADD'] },
    })
  }

  // 编辑角色
  onRoleEdit(item: any): void {
    this.$router.push({
      path: '/setting/permissions/role_edit',
      query: { mode: PageMode['EDIT'], id: item.id },
    })
  }

  // 删除角色
  onRoleDel(data: any): void {
    apiRoleDelete({
      id: data.id,
    })
      .then(() => {
        this.getRoleList()
        this.$message.success('删除成功!')
      })
      .catch(() => {
        this.$message.error('删除失败!')
      })
  }

  // 获取数据
  getRoleList(): void {
    this.pager
      .request({
        callback: apiRoleList,
      })
      .catch(() => {
        this.$message.error('数据请求失败，刷新重载!')
      })
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
    this.getRoleList()
  }
  /** E Life Cycle **/
}
